import React from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import DataTable from "react-data-table-component";
import Cookies from "js-cookie";
import DataTableExtensions from "react-data-table-component-extensions";
import { useNavigate } from "react-router-dom";
import ViewSalesProductAdminHook from "../../hook/admin/ViewSalesProductAdminHook";
import { useTranslation } from "react-i18next";

import { Helmet } from "react-helmet";

const AdminProductSalesReport = ({from,to,setFrom,setTo,loading,setLoading}) => {
    const { t } = useTranslation();

    const navigate = useNavigate();
    const [
        items,
        handleSearchDate,
        totalPrice1,
        totalPrice2,
        zeroFlag,
        setZeroFlag,
        settingsRes,
        pagination,
        getPage,
        //loading
    ] = ViewSalesProductAdminHook({from,to,loading,setLoading});

    console.log("from",from);

    const columns = [
        {
            name: t("product name"),
            sortable: true,
            cell: (row) => (
                <span
                    variant="secondary"
                    onClick={() => navigate(`/product/${row?.product?._id}`)}
                    id={row._id}
                    style={{
                        fontSize: '14px',
                        cursor: 'pointer',
                        textDecoration: 'underline'
                    }}
                >

                    {Cookies.get("i18next") === "ar" ? row?.product?.nameAr : row?.product?.nameEn}

                </span >
            ),
        },
        {
            name: t("brand"),
            //selector: (row) => Cookies.get("i18next")=="ar"?  row.brand.nameAr||' ': row.brand.nameEn||' ',
            selector: (row) => Cookies.get("i18next") === "ar" ? row?.product?.brand?.nameAr || ' ' : row?.product?.brand?.nameEn || ' ',
            sortable: true,
        },
        {
            name: t("categories"),
            // selector: (row) =>Cookies.get("i18next")=="ar"?  row.category.nameAr||' ': row.category.nameEn||' ',
            selector: (row) => Cookies.get("i18next") === "ar" ? row?.product?.category?.nameAr || ' ' : row?.product?.category?.nameEn || ' ',
            //selector:'category.nameAr',
            sortable: true,
        },
        {
            name: t("Subcategory"),
            selector: (row) => Cookies.get("i18next") === "ar" ? row?.product?.subCategory?.nameAr || ' ' : row?.product?.subCategory?.nameEn || ' ',

            sortable: true,
        },

        {
            name: t("price 1"),
            selector:(row)=>row?.product?.price1?.toFixed(2),
            //selector: "product.price1",
            sortable: true,
        },
        {
            name: t("price 2"),
            selector:(row)=>row?.product?.price2?.toFixed(2),
            //selector: "product.price2",
            sortable: true,
        },
        {
            name: t("number"),
            selector: "count",
            sortable: true,
        },

        {
            name: t("price 1"),
            selector: "totalPrice1",
            sortable: true,
        },
        {
            name: t("price 2"),
            selector: "totalPrice2",
            sortable: true,
        },


    ];

    const tableData = {
        columns,
        data: items,
    };
    return (
        <div>
            <Helmet>
                <title>{`${t("Sales Report")} -  ${Cookies.get("i18next") === "ar" ? settingsRes.data?.titleAr : settingsRes.data?.titleEn}`} </title>
            </Helmet>

            {loading&&<Spinner /> }
            <div className="d-flex justify-content-between">
                <div className="sub-tile" style={{ color: settingsRes?.data?.primaryColor }}>
                    <div className="admin-content-text">{t("Manage all products")}</div>
                </div>

            </div>

            <div className="d-inline d-flex justify-content-start ">

                <input
                    value={from}
                    type="date"
                    className="input-input-form d-block mx-1 mt-3 px-3"
                    placeholder={t("from")}
                    onChange={(e)=>setFrom(e.target.value)}
                />
                <input
                    value={to}
                    type="date"
                    className="input-input-form d-block mx-1 mt-3 px-3"
                    placeholder={("to")}
                    onChange={(e)=>setTo(e.target.value)}
                />
                <div className="mx-1 mt-4 px-3" key={`default-checkbox`}>
                    <Form.Check
                        type='checkbox'
                        id={zeroFlag}
                        label={t("hide zero")}
                        onChange={() => {
                            setZeroFlag(!zeroFlag)
                        }}
                    />
                </div>
                <Button
                    onClick={()=>handleSearchDate(from,to)}
                    className="mx-1 mt-3 px-3"
                    style={{ backgroundColor: settingsRes?.data?.primaryColor }}

                >
                    {t("search")}
                </Button>
            </div>
            <DataTableExtensions 
            {...tableData}
            filter={false} 
            filterPlaceholder={t("search here")}
            >
                <DataTable
                    noHeader
                    defaultSortAsc={false}
                    // pagination
                    highlightOnHover
                />
            </DataTableExtensions>
            <div className="d-inline d-flex justify-content-end">
                <div className="admin-content-text-data-table mx-1">{`${t("total price 1")}: `}</div>
                <input value={totalPrice1()} style={{ width: '80px' }} disabled />
                <div className="admin-content-text-data-table mx-1">{`${t("total price 2")}: `}</div>
                <input value={totalPrice2()} style={{ width: '80px' }} disabled />
            </div>
        </div>
    );
};

export default AdminProductSalesReport;
