import { t } from "i18next";
import React, { useEffect } from "react";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";

const Pagination = ({ pageCount, onPress }) => {
  
  const handlePageClick = (data) => {
    localStorage.setItem('page',data.selected+1);
    onPress(data.selected + 1);
  };


   let currentPageOnLoad=Number(localStorage.getItem('page')-1);
   if(currentPageOnLoad==null) currentPageOnLoad=0;
   let initialPage=Number(currentPageOnLoad);

//   useEffect(()=>{
//     let currentPageOnLoad=Number(localStorage.getItem('page')-1);
//   if(currentPageOnLoad==null) currentPageOnLoad=0;
//    initialPage=Number(currentPageOnLoad);
//  },[pageCount]);
  // console.log('loadingProducts in pagination');
  //   console.log(loadingProducts);
  // console.log('initialPage');
  //   console.log(initialPage);

    // console.log('pageCount');
    // console.log(pageCount);

  return (
    <ReactPaginate
      breakLabel="..."
      nextLabel={t("next")}
      onPageChange={handlePageClick}
      marginPagesDisplayed={2}
      pageRangeDisplayed={3}
      pageCount={pageCount}
      previousLabel={t("back")}
      containerClassName={"pagination justify-content-center p-3"}
      pageClassName={"page-item"}
      pageLinkClassName={"page-link"}
      previousClassName={"page-item"}
      nextClassName={"page-item"}
      previousLinkClassName={"page-link"}
      nextLinkClassName={"page-link"}
      breakClassName={"page-item"}
      breakLinkClassName={"page-link"}
      activeClassName={"active"}
       forcePage={initialPage}
     // selectedPageRel={initialPage}
       initialPage={initialPage}
      
     // pageClassName={(page) => `page-item ${currentPage === data.selected ? 'active' : ''}`}
    />
  );
};

export default Pagination;
