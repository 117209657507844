import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllVendorProductsById } from "../../redux/actions/vendorActions"
import { useLocation, useSearchParams } from "react-router-dom";


const ViewProductVendorHook = () => {
    const dispatch = useDispatch();
    const [searchTerm, setSearchTerm] = useState('');
    const [pagination, setPagination] = useState('');
    const isInitialRender = useRef(true);
    const[searchParams,setSearchParams]=useSearchParams();
    
    const vendor = JSON.parse(localStorage.getItem("user"));
    const productsByVendorId = useSelector((state) => state.vendorReducer.getProductsByVendorId);
   // const history = useHistory();

    
    // const lastVisitedUrl = document.referrer;
    //  console.log('lastVisitedUrl');
    //  console.log(history.location);
    
    

   // let query=`limit=9&mobile=1&admin=1`;

    console.log(searchParams.get('page'));

    useEffect(() => {
       
        console.log('get page use effect ');
        let firstPage=searchParams.get('page');
        // if (localStorage.getItem('page'))
        // firstPage = (searchParams.get('page')==1) ? 1 : localStorage.getItem('page');    
        // else firstPage = 1;
        let query=`limit=9&mobile=1&admin=1`;
         query+=`&page=${firstPage}`;
         
         console.log('query');
        console.log(query);
         
            //getPage(firstPage);
        //if(isInitialRender)
        dispatch(getAllVendorProductsById(vendor._id, query));
    }, []);

    useEffect(() => {
       // if (isInitialRender.current) return;
        if (productsByVendorId) setPagination(productsByVendorId.numberOfPages)
    }, [productsByVendorId]);

    const getPage = async (page) => {
        if (isInitialRender.current) return;
        
        searchParams.set("page", page);
        setSearchParams(searchParams);
        //searchParams.set("page", page);
        localStorage.setItem('page', page);

        let searchTerms = localStorage.getItem('searchTerm');
        let query=`limit=9&mobile=1&admin=1`;
         query+=`&page=${page}`;

       await dispatch(getAllVendorProductsById(vendor._id,query));
    };

    useEffect(() => {
       // if(localStorage.getItem("editPage")===true) return;
       if (isInitialRender.current) {
        isInitialRender.current = false;
        return;
      }
       // if (productsByVendorId.length == 0) return;

        if (searchTerm === '') {
            setSearchTerm('');
            localStorage.setItem('searchTerm', '');
            
            getPage(1);
            localStorage.setItem("page", 1);
            return;
        }

        const timeOutId=setTimeout(async()=>{
            localStorage.setItem('searchTerm', searchTerm);

            let query=`keyword=${searchTerm}&limit=9&mobile=1&admin=1&page=1`;
        // console.log('xxxxxxxxxxxxxxxxxxxxxxxxxxxx');
         dispatch(getAllVendorProductsById(vendor._id, query));
         localStorage.setItem("page", 1);
        },800);

        return ()=>clearTimeout(timeOutId)
       
    }, [searchTerm]);

  

    return [productsByVendorId, searchTerm, setSearchTerm, pagination, getPage]
}

export default ViewProductVendorHook