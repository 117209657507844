import { GET_ALL_CATEGORY, CREATE_CATEGORY, GET_ERROR ,GET_ALL_CATEGORIES ,DELETE_CATEGORY ,GET_ONE_CATEGORY,UPDATE_CATEGORY,GET_ALL_CATEGORIES_WITHOUT_BLOCHED_GATEGORY,GET_CATEGORIES_BY_ADMIN} from "../type";
import { useGetData } from "../../hooks/useGetData";
import { useInsertDataWithImage } from "../../hooks/useInsertData";
import useDeleteData from "../../hooks//useDeleteData";
import { useUpdateDataWithImage } from "../../hooks/useUpdateData";


//get all categories
export const getAllCategories = (limit,status) => async(dispatch) => {
  try {
    
    // let url=`/api/v1/categories?limit=${limit}`;
    // if(status) url+=`&status=true`;
    const response = await useGetData(`/api/v1/categories`);
    dispatch({
      type:GET_ALL_CATEGORIES,
      payload:response
    })
  } catch (error) {
    dispatch({
      type:GET_ALL_CATEGORIES,
      payload:error.response
    })
  }
}
export const getAllCategoriesWithoutBlockedCategory = () => async(dispatch) => {
  try {
    const response = await useGetData(`/api/v1/categories/withoutBlochedCategory`)
    // console.log('++++++++++++++++++++++++++++++++')

    // console.log(response)
    dispatch({
      type:GET_ALL_CATEGORIES_WITHOUT_BLOCHED_GATEGORY,
      payload:response
    })
  } catch (error) {
    dispatch({
      type:GET_ALL_CATEGORIES_WITHOUT_BLOCHED_GATEGORY,
      payload:error.response
    })
  }
}

export const getAllCategory = (limit) => async (dispatch) => {
  try {
    const response = await useGetData(
      `/api/v1/categories/website?limit=${limit}`
    );

    dispatch({
      type: GET_ALL_CATEGORY,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: GET_ERROR,
      payload: error,
    });
  }
};


export const getAllCategoryPage = (page) => async (dispatch) => {
  try {
    const response = await useGetData(
      `/api/v1/categories/website?limit=6&page=${page}`
    );

    dispatch({
      type: GET_ALL_CATEGORY,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: GET_ERROR,
      payload: error,
    });
  }
};

//create Category
export const createCategory = (formData) => async (dispatch) => {
  try {
    const response = await useInsertDataWithImage(
      `/api/v1/categories`,
      formData
    );

    dispatch({
      type: CREATE_CATEGORY,
      payload: response,
      loading: true,
    });
  } catch (error) {
    dispatch({
      type: GET_ERROR,
      payload: error,
    });
  }
};

// get one category
export const getOneCategory = (id) => async(dispatch) => {
  try {
    const response = await useGetData(`/api/v1/categories/${id}`)
    dispatch({
      type:GET_ONE_CATEGORY,
      payload:response,
      loading:true
    })
  } catch (error) {
    dispatch({
      type:GET_ONE_CATEGORY,
      payload:error.response
    })
  }
}

//update Category 
export const updateCategory = (id, formData) => async (dispatch) => {
  try {
    const response = await useUpdateDataWithImage(`/api/v1/categories/${id}`, formData);
    // console.log('---------------')
    // console.log(response)
    // console.log('---------------')
    
    dispatch({
      type: UPDATE_CATEGORY,
      payload: response,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_CATEGORY,
      payload: error.response,
    });
  }
};

//delete category
export const deleteCategory = (id) => async(dispatch) => {
  try {
    const response = await useDeleteData(`/api/v1/categories/${id}`)
    dispatch({
      type:DELETE_CATEGORY,
      payload:response
    })
  } catch (error) {
    dispatch({
      type:DELETE_CATEGORY,
      payload:error.response
    })
  }
}

export const getAllCategoriesByAdmin=()=>async(dispatch)=>{
  try {
    const response=await useGetData('/api/v1/categories/admin');
    dispatch({
      type:GET_CATEGORIES_BY_ADMIN,
      payload:response
    });
  } catch (error) {
    dispatch({
      type:GET_CATEGORIES_BY_ADMIN,
      payload:error
    })
  }
}