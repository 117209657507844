import React from "react";
import { Row, Col, Modal, Button } from "react-bootstrap";
import UserAllOrderCard from "./UserAllOrderCard";
import Cookies from "js-cookie";
import UserGetOrdersHook from "../../hook/user/UserGetOrdersHook";
import { GiReturnArrow } from "react-icons/gi";

import { t } from "i18next";
import { useSelector } from "react-redux";
import axios from "axios";
import { useParams, useSearchParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useState } from "react";

const UserAllOrderItem = ({ orderItem }) => {
  //  const [success,setSuccess] = useState(searchParams.get("success"))
  // console.log('searchParams')
  // console.log(success)
  const [
    username,
    result,
    orderData,
    noPages,
    onPress,
    clickHandler,
    handlePrintShipments,
    user,
    showModalReturnProduct,
    description,
    show,
    onChangeDescription,
    handleCloseModel,
    saveReturnProduct,
  ] = UserGetOrdersHook();
  
  const res = useSelector((state) => state.orderReducer.ordersByUser);

  // console.log('reeeeeeeeeeeeees');
  // console.log(res);
  //const updateOrder=useSelector((state)=>state.orderReducer.updateOrder);

  // console.log('updateOrder');
  // console.log(updateOrder);

  const settingsRes = useSelector((state) => state.settingsReducers.settings);
  const currencytype =
    Cookies.get("i18next") === "ar"
      ? settingsRes.data?.defaultCurrancy.ar
      : settingsRes.data?.defaultCurrancy.en;
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  console.log('orderItem.totalPrice');
  console.log(orderItem);
  return (
    <div className="user-order mt-2">
      <Modal show={show} onHide={handleCloseModel}>
        <Modal.Header>
          <Modal.Title>
            <div className="font">{t("من فضلك أدخل سبب الإسترجاع ")}</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="font">
            <textarea
              id="description"
              onChange={onChangeDescription}
              value={description}
              className="input-form-area p-2 mt-2"
              rows="4"
              cols="50"
              placeholder={t("سبب الإسترجاع")}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            className="font"
            onClick={() => saveReturnProduct()}
          >
            {t("Save Changes")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Row className="d-flex justify-content-between">
        <Col>
          <div
            className="py-2 order-title"
            style={{ color: settingsRes?.data?.primaryColor }}
          >
            {t("order number")}#{orderItem.orderNumber} ..{t("done with date")}
            ... {formatDate(orderItem.createdAt)}
          </div>
        </Col>
        <Col className="p-2 mt-3 mx-2">
          <button
            onClick={() => clickHandler(orderItem._id,Number(orderItem.totalPrice).toFixed(2))}
            className="print-button"
          >
            <span className="print-icon"></span>
            <div>{t("View the bill")}</div>
          </button>
        </Col>
      </Row>
      {user?.role === "user" ? (
        <button
          onClick={() => showModalReturnProduct(orderItem?.orderNumber)}
          className="print-button"
        >
          <span style={{ fontSize: "25px" }}>
            <GiReturnArrow />{" "}
          </span>{" "}
          <div>{t("Product return request")}</div>
        </button>
      ) : null}
      {orderItem.orderItems
        ? orderItem.orderItems.map((el) => {
          return <UserAllOrderCard key={el._id} item={el} />;
        })
        : null}

      <Row className="d-flex justify-content-between">
        <Col xs="12" className="d-block d-sm-flex">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="d-inline">{t("order state")}</div>
            <div className="d-inline mx-2 stat">
              [ {orderItem.orderStatusId && orderItem.orderStatusId.nameAr}]
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="d-inline">{t("payment")}</div>
            <div className="d-inline mx-2 stat">
              {orderItem.isPaid === true ? [t("paid")] : [t("not paid")]}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="d-inline">طريقة الدفع</div>
            <div className="d-inline mx-2 stat">
              {orderItem?.paymentMethodId?.nameAr}
            </div>
          </div>
        </Col>

        <Col xs="12" className="d-flex justify-content-end">
          <div style={{ textAlign: "left" }}>
            <div className="barnd-text mb-3 mx-2">
              {(Number(orderItem.totalPrice).toFixed(2) ) || 0} {currencytype}
            </div>
            {orderItem?.shipmentReferenceId &&
              <div className="barnd-text mb-3 mx-2" style={{ cursor: 'pointer' }} onClick={() => handlePrintShipments(orderItem._id)}>
                طباعه بوليصه الشحن
              </div>
            }
          </div>
        </Col>
      </Row>
      <ToastContainer />
    </div>
  );
};

export default UserAllOrderItem;
