import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import CategoryHeader from "../../Components/Category/CategoryHeader";
import CardProductsContainer from "../../Components/Products/CardProductsContainer";
import Pagination from "../../Components/Utilities/Pagination";
import ViewAllProductByBrandHook from "../../hook/product/ViewAllProductByBrandHook";

import { Helmet } from "react-helmet";
import SideFilter from "../../Components/Utilities/SideFilter";
import ViewSearchProductHooks from "../../hook/product/ViewSearchProductHooks";
import LoadingSpinner from "../../Components/Utilities/LoadingSpinner";

const ProductsByBrands = () => {
  const { id } = useParams();
  const [, , , brandById, settingsRes] = ViewAllProductByBrandHook(id);
  const [
    items,
    pagination,
    onPress,
    getProduct,
    result,
    offers,
    selectedCategorySearch,
    selectedBrandSearch,
    search,
    onChangeSetMinPrice,
    onChangeSetMaxPrice,
    minPrice,
    maxPrice,
    loadingSpinner,
  ] = ViewSearchProductHooks("" , undefined,undefined,id);
  let hideCategory = false;
  let hideBrands = true;
  if (pagination) var pageCount = pagination;
  else pageCount = 0;

  // console.log('items');
  // console.log(items);
  console.log('settingsRes?.data?.titleAr');
  console.log(settingsRes?.data?.titleAr);
  //{`  ${items[0]?.brand?.nameAr || ' '} ${items[0] ? '-' : ' '} ${settingsRes?.data?.titleAr}`}
  return (
    <div style={{ minHeight: "670px" }}>

      <head>
        <title> البراندات</title>
      </head>

      <CategoryHeader />
      <div
        style={{
          background: settingsRes?.data?.secondaryColor,
          height: "296px",
        }}
      >
        <div
          className="d-flex justify-content-center"
          style={{
            height: "296px",
            alignItems: "center",
          }}
        >
          <img src={brandById?.logo} width="260px" alt = "Brand Logo" className="mx-3" />
          <div style={{ fontWeight: "bold", fontSize: "24px" }}>
            {brandById?.nameAr}
          </div>
        </div>
      </div>
      <Container>
      <Row className="d-flex justify-content-center">
      <Col sm="2" xs="2" md="2" className="d-flex ">
        <SideFilter
          props={{
            selectedCategorySearch,
            selectedBrandSearch,
            search,
            onChangeSetMinPrice,
            onChangeSetMaxPrice,
            hideCategory,
            hideBrands
          }}
        />
      </Col>
      <Col md="10" sm="10">
        {loadingSpinner === true ? (
          <LoadingSpinner />
        ) : (
          <CardProductsContainer products={items} title="" btntitle="" />
        )}
      </Col>
    </Row>
        <Pagination pageCount={pageCount} onPress={onPress} />
      </Container>
    </div>
  );
};

export default ProductsByBrands;
