import  { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  
  getAllCartItems,
} from "../../redux/actions/cartAction";

const UserCartHook = () => {
  var user = "";
  if (localStorage.getItem("user") != null) {
    user = JSON.parse(localStorage.getItem("user"));
  }
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [itemNumber, setItemsNumber] = useState(0);
  const [cartItems, setCartItems] = useState([]);
  const [couponNameAfterApplay, setCouponNameAfterApplay] = useState("");
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalPriceAfterDiscount, setTotalPriceAfterDiscount] = useState(0);
  const[shippingCost,setShippingCost]=useState(0);
  //const[loading,setLoading]=useState(false);


  const [vendorId, setvendorId] = useState('');
  const [couponId, steCouponId] = useState('');

  useEffect(() => {
    if (user != "") {
      const get = async () => {
        setLoading(true);
        // console.log('user.........');
        // console.log(user._id);
        await dispatch(getAllCartItems(user._id));
        setLoading(false);
      };
      get();
    }
  }, []);
  const res = useSelector((state) => state.cartReducer.userCartItems);

  useEffect(() => {
    if (loading === false) {
      if (res && res.success === true) {

        setItemsNumber(res.numberOfCart);
        setCartItems(res.data);
        setvendorId(res.data[0]?.product?.vendor)
        setTotalPrice(res.totalPrice);
        setShippingCost(res.totalShippingCost);

        if (res.data.length != 0 && res.data[0].coupon) {
          setCouponNameAfterApplay(res.data[0].coupon);
          steCouponId(res.data[0]._id);
          //setTotalPriceAfterDiscount(res.data[1].priceAfter);
         // let totalPriceAfterCoupon=0;
          const totalpriceAfterDiscount =
          res.data[0].couponId.type === 1
        ? res.totalPrice - res.data[0].couponId.value
        : (res.totalPrice * res.data[0].couponId.value) / 100;

        console.log('res');
        console.log(res);
        console.log(totalpriceAfterDiscount);

        setTotalPriceAfterDiscount(totalpriceAfterDiscount);
          //totalPriceAfterCoupon=res.data.map(el=>);

        } else {
          setCouponNameAfterApplay("");
          steCouponId("");
          setTotalPriceAfterDiscount(0);
        }
      } else {
        setItemsNumber(0);
        setCartItems([]);
        setvendorId("");

        setCouponNameAfterApplay("");
        steCouponId("");
        setTotalPriceAfterDiscount(0);
      }
    }
  }, [loading]);

  return [
    res,
    cartItems,
    totalPrice,
    couponNameAfterApplay,
    totalPriceAfterDiscount,
    vendorId,
    couponId,
    shippingCost,
    loading
  ];
};

export default UserCartHook;
