import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  getAllCategories,
  getAllCategory,
  getOneCategory,
} from "../../redux/actions/categoryAction";
import { getAllBrand } from "../../redux/actions/brandAction";
import { subCategoryByCatId } from "../../redux/actions/subCategoryAction";
import {
  getOneProduct,
  updateProduct,
} from "../../redux/actions/productsAction";
import { getAllDropShippings } from "../../redux/actions/dropShippingAction";
import notify from "../../hook/useNotification";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getAllVendors } from "../../redux/actions/usersActions";

const EditProductHook = (id) => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const ref = useRef(null);

  useEffect(() => {
    const run = async () => {
      await dispatch(getAllDropShippings());
      await dispatch(getOneProduct(id));
      await dispatch(getAllCategories());
      await dispatch(getAllBrand());
      await dispatch(getAllVendors());
    };
    run();
  }, []);

  const item = useSelector((state) => state.allProduct.oneProduct);
  const allVendorsResponse = useSelector(
    (state) => state.usersReducers.allVendors
  );

  // console.log('allVendorsResponse');
  // console.log(allVendorsResponse);

  //   console.log(item);
  const category = useSelector((state) => state.allCategory.categories);
  console.log("category");
  console.log(category);
  const brand = useSelector((state) => state.allBrand.brand);
  const dropShippingsResponse = useSelector(
    (state) => state.dropShippingReducer.allDropShippings
  );
  const subcategory = useSelector(
    (state) => state.subCategory.subCategoryByCat
  );

  const [images, setImages] = useState([]);
  const [nameAr, setNameAr] = useState("");
  const [weight, setWeight] = useState("");

  const [nameEn, setNameEn] = useState("");
  const [descriptionAr, setDescriptionAr] = useState("");
  const [descriptionEn, setDescriptionEn] = useState("");
  const [richDescriptionAr, setRichDescriptionAr] = useState("");
  const [richDescriptionEn, setRichDescriptionEn] = useState("");
  //السعر
  const [price1, setPrice1] = useState("");
  //السعر الاضافي
  const [price2, setPrice2] = useState("");

  const [price3, setPrice3] = useState("");
  const [price4, setPrice4] = useState("");
  const [countInStock, setCountInStock] = useState("");

  const [categoryId, setCategoryId] = useState(0);
  const [subCategory, setSubCategory] = useState(0);
  const [selectedsubId, setSelectedsubId] = useState("");
  const [isFeatured, setIsFeatured] = useState(false);
  const [brandId, setBrandId] = useState("");
  const [ProductStatus, setProductStatus] = useState("");
  const [descriptionProduct, setDescriptionProduct] = useState("");
  const [keywordsProducts, setKeywordsProducts] = useState("");
  const [video, setVideo] = useState("");
  const [value, setValue] = useState("");
  const [tableSizes, settableSizes] = useState([]);
  const [categoryObject, setCategoryObject] = useState({});
  const [subCategoryObject, setSubCategoryObject] = useState({});
  const [dropShippingType, setDropShippingType] = useState("");
  const [productPriceDropShipping, setProductPriceDropShipping] = useState(0);
  const [productCodeDropShipping, setProductCodeDropShipping] = useState("");
  const [updateProductPriceDropShipping, setUpdateProductPriceDropShipping] =
    useState(true);

  const [allVendors, setAllVendors] = useState({});
  const [vendor, setVendor] = useState();

  const [tableSkuSizes, settableSkuSizes] = useState([]);
  const [hasChildClassifications, setHasChildClassifications] = useState("");
  const [DropShippingCode, setDropShippingCode] = useState("");

  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (item) {
      if (item.images) setImages(item.images);
      else setImages([]);
      //   setImages(item.images);
      setNameAr(item.nameAr);
      setNameEn(item.nameEn);
      setDescriptionProduct(item.descriptionProduct);
      setKeywordsProducts(item.keywordsProducts);
      setDescriptionAr(item.descriptionAr);
      setDescriptionEn(item.descriptionEn);
      setRichDescriptionAr(item.richDescriptionAr);
      setRichDescriptionEn(item.richDescriptionEn);
      setPrice1(item.price1);
      setPrice2(item.price2);
      setPrice3(item.price3);
      setPrice4(item.price4);
      setProductStatus(item.status);
      setCountInStock(item.countInStock);
      setIsFeatured(item.isFeatured);
      setWeight(item.weight);
      setVideo(item.video);
      settableSizes(item.classifications);
      setDropShippingType(item.dropShippingType);
      setProductPriceDropShipping(item.productPriceDropShipping);
      setProductCodeDropShipping(item.productCodeDropShipping);
      setUpdateProductPriceDropShipping(item.updateProductPriceDropShipping);

      //   console.log(item.category);
      if (item.category) setCategoryId(item.category.id);
      if (item.brand) setBrandId(item.brand._id);
      if (item.colors) setColors(item.colors);
      if (item.category) setSelectedsubId(item.subCategory);
      if (item.category) setCategoryObject(item.category);
      if (item.category) dispatch(getOneCategory(item.subCategory));
      if (item.vendor) setVendor(item?.vendor);
      setHasChildClassifications(item?.hasVariant?.toString());
      setDropShippingCode(item?.productCodeDropShipping);
      // console.log('item?.variants');
      // console.log(item?.variants);
      settableSkuSizes(item?.variants);
    }
  }, [item]);

  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    if (allVendorsResponse) setAllVendors(allVendorsResponse);
  }, [allVendorsResponse]);

  const onChangeDropShippingCode = (e) => {
    setDropShippingCode(e.target.value);
  };

  const onChangeHasChildClassifications = (e) => {
    setHasChildClassifications(e.target.value);
  };

  const onChangeVideo = (e) => {
    setVideo(e.target.value);
  };

  const onChangeNameAr = (e) => {
    setNameAr(e.target.value);
  };
  const onChangeNameEn = (e) => {
    setNameEn(e.target.value);
  };
  const onChangeDescriptionAr = (e) => {
    setDescriptionAr(e.target.value);
  };
  const onChangeWeight = (e) => {
    setWeight(e.target.value);
  };
  const onChangeDescriptionEn = (e) => {
    setDescriptionEn(e.target.value);
  };
  const onChangeRichDescriptionAr = (e) => {
    setRichDescriptionAr(e.target.value);
  };
  const onChangeRichDescriptionEn = (e) => {
    setRichDescriptionEn(e.target.value);
  };
  const onChangePrice1 = (e) => {
    setPrice1(e.target.value);
  };
  const onChangePrice2 = (e) => {
    setPrice2(e.target.value);
  };
  const onChangePrice3 = (e) => {
    setPrice3(e.target.value);
  };
  const onChangePrice4 = (e) => {
    setPrice4(e.target.value);
  };
  const onChangeCountInStock = (e) => {
    setCountInStock(e.target.value);
  };

  const onChangeValue = (e) => {
    e.persist();
    setValue(e.target.value);
  };

  // onchange Dropshipping
  const onChangeDropShipping = (e) => {
    setDropShippingType(e.target.value);
  };

  // onChange price of dropshipping
  const onChangeProductPriceDropShipping = (e) => {
    setProductPriceDropShipping(e.target.value);
  };

  // onChange productCodeDropShipping
  const onChangeProductCodeDropShipping = (e) => {
    setProductCodeDropShipping(e.target.value);
  };

  const onChangeUpdateProductPriceDropShipping = (e) => {
    setUpdateProductPriceDropShipping(e.target.checked);
  };

  // show color picker
  const [showPicker, setShowPicker] = useState(false);
  const [colors, setColors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [check, setCheck] = useState(false);

  const onSelectCategory = async (value) => {
    console.log("value");
    console.log(value);
    //return;
    if (value === null) {
      setCategoryObject({});
      setSubCategoryObject({});
    }

    if (value && value._id !== 0 && value._id !== "0") {
      setCategoryObject(value);
      setCategoryId(value._id);

      await dispatch(subCategoryByCatId(value._id));

      if (value._id !== subCategoryObject.parentId) {
        //setSubCategoryObject({});
        //setSelectedsubId(0);
      }
    }
  };

  const onChangeVendor = async (value) => {
    console.log("value");
    console.log(value);
    if (value === null) setVendor(null);
    if (value && value._id !== 0 && value._id !== "0") {
      setVendor(value);
    }
  };

  const subCategoryRes = useSelector((state) => state.allCategory.oneCategory);
  useEffect(() => {
    console.log("subCategoryRes");
    console.log(subCategoryRes.length);

    if (subCategoryRes) {
      // console.log("subCategoryRes");
      // console.log(subCategoryRes);
      //setSubCategory(subCategoryRes);
      subCategoryRes.length === undefined
        ? setSubCategoryObject(subCategoryRes)
        : setSubCategory(subCategoryRes);
    }
  }, [subCategoryRes]);

  const onSelectSubCategory = (value) => {
    if (value === null) {
      setSubCategoryObject({});
    }

    if (value && value._id !== 0 && value._id !== "0") {
      setSubCategoryObject(value);
      setSelectedsubId(value._id);
    }
  };

  const onChangePicker = (e) => {
    setShowPicker(!showPicker);
  };

  const onChangeDescriptionProduct = (e) => {
    e.persist();
    setDescriptionProduct(e.target.value);
  };

  const onChangeKeywordsProducts = (e) => {
    e.persist();
    setKeywordsProducts(e.target.value);
  };
  const onChangeProductStatus = (e) => {
    e.persist();
    setProductStatus(e.target.value);
  };

  useEffect(() => {
    if (categoryId != 0) {
      const run = async () => {
        await dispatch(subCategoryByCatId(categoryId));
      };
      run();
    }
  }, [categoryId, dispatch]);

  useEffect(() => {
    if (subcategory) {
      setSubCategory(subcategory);
    }
  }, [subcategory]);

  const selectedFeatured = (e) => {
    setIsFeatured(e.target.value);
  };
  const onSelectBrand = (e) => {
    setBrandId(e.target.value);
  };
  const handleChaneComplete = (color) => {
    setColors([...colors, color.hex]);
    setShowPicker(!showPicker);
  };

  const removeColor = (color) => {
    const newColorArray = colors.filter((e) => e !== color);
    // console.log("newColorArray");
    // console.log(newColorArray);
    setColors(newColorArray);
  };

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }
  //convert url to file
  const convertURLtoFile = async (url) => {
    const response = await fetch(url, { mode: "cors" });
    const data = await response.blob();
    const ext = url.split(".").pop();
    const filename = url.split("/").pop();
    const metadata = { type: `image/${ext}` };
    return new File([data], Math.random(), metadata);
  };

  // update sizes of product
  const clickHandler = () => {
    if (value === "") {
      return notify(
        t("Please enter the available size of the product"),
        "warn"
      );
    }

    settableSizes([...tableSizes, value]);
    setValue("");
  };

  const deleteElement = (idx) => {
    settableSizes(tableSizes.filter((el, index) => index !== idx));
  };

  const deleteSkuElement = (idx) => {
    settableSkuSizes(tableSkuSizes.filter((el, index) => index !== idx));
  };

  const handleSubmit = async (e) => {
    // notify(t("Please add Image for product"), "warn");
    if (!vendor?._id) {
      //console.log('doneeeeeeeeeeeee')
      return notify(t("select vendor name"), "warn");
    }

    let itemImages = [];
    //convert array of base 64 image to file
    Array.from(Array(Object.keys(images).length).keys()).map((item, index) => {
      if (images[index].length <= 1000) {
        // console.log("dd");
        convertURLtoFile(images[index]).then((val) => itemImages.push(val));
      } else {
        // console.log("ee");
        itemImages.push(dataURLtoFile(images[index], Math.random() + ".png"));
      }
    });

    e.preventDefault();

    // let check = tableSkuSizes.find(obj => obj.isDefault == "true" || obj.isDefault == true );

    // if (!check) return notify('من فضلك اختر منتج  فرعي علي الاقل ك افتراضي', "error");

    if (hasChildClassifications == "true" && tableSkuSizes.length === 0)
      return notify(
        t("please select at least one child classification"),
        "error"
      );

    if (nameAr === "") {
      return notify(
        t("Please enter the name of the product in Arabic"),
        "warn"
      );
    }
    if (nameEn === "") {
      return notify(t("Please enter the product name in English"), "warn");
    }
    if (categoryId === 0 || categoryId === "" || categoryId === "0") {
      return notify(
        t("Please choose the main category of the product"),
        "warn"
      );
    }
    if (subCategory === 0 || subCategory === "" || subCategory.length === 0) {
      return notify(t("Please choose another main product category"), "warn");
    }
    if (selectedsubId === 0 || selectedsubId === "") {
      return notify(t("Please select a product subcategory"), "warn");
    }
    if (
      (countInStock <= 0 || countInStock === "") &&
      hasChildClassifications == "false"
    ) {
      return notify(
        t("Determine the available quantity of the product in stock"),
        "warn"
      );
    }
    if ((price1 <= 0 || price1 === "") && hasChildClassifications == "false") {
      return notify(t("Please add a price to the product"), "warn");
    }
    if ((weight <= 0 || weight === "") && hasChildClassifications == "false") {
      return notify(t("Please add the weight of the product"), "warn");
    }
    if (descriptionAr === "") {
      return notify(
        t("Please enter a description of the product in Arabic"),
        "warn"
      );
    }
    if (descriptionEn === "") {
      return notify(
        t("Please enter a description of the product in English"),
        "warn"
      );
    }

    if (images.length <= 0) {
      return notify(t("Please add Image for product"), "warn");
    }

    const formData = new FormData();
    formData.append("nameAr", nameAr);
    formData.append("nameEn", nameEn);
    formData.append("descriptionAr", descriptionAr);
    formData.append("weight", weight);

    formData.append("descriptionEn", descriptionEn);
    formData.append("richDescriptionAr", richDescriptionAr);
    formData.append("richDescriptionEn", richDescriptionEn);
    formData.append("price1", price1);
    formData.append("price2", price2);
    formData.append("price3", price3);
    formData.append("price4", price4);
    formData.append("countInStock", countInStock);
    formData.append("category", categoryId);
    formData.append("subCategory", selectedsubId);
    formData.append("isFeatured", isFeatured);
    formData.append("status", ProductStatus);
    formData.append("descriptionProduct", descriptionProduct);
    formData.append("keywordsProducts", keywordsProducts);
    formData.append("video", video);
    formData.append("vendor", vendor._id);

    if (brandId !== "") {
      formData.append("brand", brandId);
    }

    if (
      dropShippingType !== "" &&
      dropShippingType !== 0 &&
      dropShippingType !== undefined &&
      dropShippingType !== null
    ) {
      // console.log('dropShippingTypewwwwwww')
      // console.log(dropShippingType)
      formData.append("dropShippingType", dropShippingType);
      formData.append("productPriceDropShipping", productPriceDropShipping);
      formData.append("productCodeDropShipping", productCodeDropShipping);
      formData.append(
        "updateProductPriceDropShipping",
        updateProductPriceDropShipping
      );
    }

    // if(dropShippingType !== '')

    ref.current.continuousStart();

    // console.log("colors");
    // console.log(colors);
    // console.log("itemImages");
    // console.log(itemImages);
    if (colors.length !== 0) {
      colors.map((color) => formData.append("colors", color));
    } else {
      formData.append("colors", "");
    }
    // setTimeout(() => {
    itemImages.map((item) => formData.append("images", item));
    // }, 1000);
    tableSizes.map((size) => formData.append("classifications", size));
    setCheck(true);

    formData.append("DropShippingCode", DropShippingCode);
    hasChildClassifications &&
      formData.append("hasVariant", hasChildClassifications);
    hasChildClassifications &&
      formData.append("variants", JSON.stringify(tableSkuSizes));

    // setTimeout(async () => {
    setLoading(true);
    await dispatch(updateProduct(id, formData));
    setLoading(false);
    setCheck(false);
    // }, 1000);
  };

  const product = useSelector((state) => state.allProduct.updateProduct);
  useEffect(() => {
    if (loading === false) {
      // setImages([]);
      // setNameAr("");
      // setNameEn("");
      // setDescriptionAr("");
      // setDescriptionEn("");
      // setRichDescriptionAr("");
      // setRichDescriptionEn("");
      // setPrice1("السعر");
      // setPrice2("السعر الاضافي");
      // setPrice3("السعر الاضافي");
      // setPrice4("السعر الاضافي");
      // setCountInStock("الكمية");
      // setCategoryId(0);
      // setSubCategory(0);
      // setSelectedsubId("");
      // setIsFeatured(false);
      // setBrandId(0);
      // setColors([]);
      setTimeout(() => setLoading(true), 1500);
      if (product) {
        if (product.status === 200) {
          notify(t("Modified successfully"), "success");
          ref.current.complete();

          let navigateUrl = "";
          if (user.role === "admin")
            navigateUrl = `/admin/products?page=${searchParams.get("page")}`;
          else
            navigateUrl = `/vendor/allproducts?page=${searchParams.get(
              "page"
            )}`;

          setTimeout(() => {
            navigate(navigateUrl);
          }, 1000);
        } else {
          notify(t("Failed in the editing process"), "error");
          ref.current.complete();
        }
      }
    }
  }, [loading]);

  return [
    categoryId,
    brandId,
    subCategory,
    selectedsubId,
    isFeatured,
    images,
    nameAr,
    nameEn,
    descriptionAr,
    descriptionEn,
    richDescriptionAr,
    richDescriptionEn,
    price1,
    price2,
    price3,
    price4,
    countInStock,
    showPicker,
    colors,
    category,
    brand,
    onSelectSubCategory,
    selectedFeatured,
    onSelectBrand,
    handleChaneComplete,
    onSelectCategory,
    removeColor,
    handleSubmit,
    setImages,
    onChangeNameAr,
    onChangeNameEn,
    onChangeDescriptionAr,
    onChangeDescriptionEn,
    onChangeRichDescriptionAr,
    onChangeRichDescriptionEn,
    onChangePrice1,
    onChangePrice2,
    onChangePrice3,
    onChangePrice4,
    onChangeCountInStock,
    onChangePicker,
    loading,
    check,
    descriptionProduct,
    keywordsProducts,
    onChangeDescriptionProduct,
    onChangeKeywordsProducts,
    ProductStatus,
    onChangeProductStatus,
    weight,
    onChangeWeight,
    ref,
    onChangeVideo,
    video,
    value,
    tableSizes,
    onChangeValue,
    clickHandler,
    deleteElement,
    categoryObject,
    subCategoryObject,
    dropShippingType,
    productPriceDropShipping,
    productCodeDropShipping,
    updateProductPriceDropShipping,
    onChangeDropShipping,
    onChangeProductPriceDropShipping,
    onChangeProductCodeDropShipping,
    onChangeUpdateProductPriceDropShipping,
    dropShippingsResponse,
    vendor,
    allVendors,
    onChangeVendor,
    user,

    deleteSkuElement,
    tableSkuSizes,
    settableSkuSizes,
    hasChildClassifications,
    onChangeHasChildClassifications,
    DropShippingCode,
    onChangeDropShippingCode,
  ];
};

export default EditProductHook;
