import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers, upDatePasswordByAdmin, updateUser } from "../../redux/actions/usersActions";
import { useNavigate } from "react-router-dom";
import { getWebsiteData  ,getSettings} from "../../redux/actions/settingsAcions";
import notify from "../useNotification";

const ViewAllUsersHook = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const[showChangePasswordModal,setShowChangePasswordModal]=useState(false);
  const[changePasswordId,setChangePasswordId]=useState("");
  const[newPassword,setNewPassword]=useState("");

  const handleShowFun = async (id, status) => {
    setLoading(true);
    await dispatch(
      updateUser(id, {
        status: status,
      })
    );
    setLoading(false);
  };
  const res = useSelector((state) => state.usersReducers.updateUser);

  useEffect(() => {
    if (loading === false) {
      if (res && res.success === true) {
        notify("تم التعديل بنجاح", "success");
        setTimeout(() => {
          dispatch(getAllUsers());
        }, 1000);
      } else {
        notify("فشل في عملية التعديل", "error");
      }
    }
  }, [loading]);






  useEffect(() => {
    dispatch(getAllUsers());
  }, []);
  const allUsers = useSelector((state) => state.usersReducers.allUser);
  const webSiteData = useSelector((state) => state.settingsReducers.webSiteData);
  const settings = useSelector((state) => state.settingsReducers.settings);
  const updatePasswordByAdmin = useSelector((state) => state.usersReducers.updatePasswordByAdmin);
  //const updatingPassword = useSelector((state) => state.usersReducers.updatingPassword);
const showReviews = settings.data?.showReviews
  let users = [];
  if (allUsers) users = allUsers;

  const clickHandler = (id) => {
    navigate(`/admin/edituser/${id}`);
  };

  const changePasswordForUser=(e)=>{
    setNewPassword(e.target.value);
  }

  const upDatePassword=async()=>{
    console.log('newPassword in update method');
    console.log(newPassword);
    await dispatch(upDatePasswordByAdmin({
      id:changePasswordId,
      newPassword:newPassword
    }));
   
  }

  useEffect(()=>{
    if(changePasswordId=="") return;
    if(updatePasswordByAdmin._id)
    {
      setShowChangePasswordModal(false);
        setChangePasswordId("");
        setNewPassword("");
        notify("تم التعديل بنجاح", "success");
    }
    else  notify("فشل في عملية التعديل", "error");
  },[updatePasswordByAdmin]);

  console.log('changePasswordId');
  console.log(changePasswordId);

  return [users, clickHandler ,webSiteData , handleShowFun , showReviews , settings,showChangePasswordModal,setShowChangePasswordModal,setChangePasswordId,changePasswordForUser,newPassword,upDatePassword];
};

export default ViewAllUsersHook;
