import React from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import ViewAllUsersHook from "../../hook/admin/ViewAllUsersHook";
import UserGetOrdersHook from "../../hook/user/UserGetOrdersHook";
import mobile from "../../Images/mobile.png";
import { useTranslation } from "react-i18next";
const UserAllOrderCard = ({ item }) => {
  const { t } = useTranslation()
  const [users, clickHandler, webSiteData, handleShowFun, showReviews] =
    ViewAllUsersHook();

  return (
    <div>
      <Row className="d-flex mb-2">
        <Col xs="3" md="2" className="d-flex justify-content-start">
          <Link
            to={`/product/${item.product._id}`}
            style={{ textDecoration: "none" }}
          >
            <img
              width="93px"
              height="120px"
              src={(item.product && item.product.image) || mobile}
              alt=""
              style={{ objectFit: "contain" }}
            />
          </Link>
        </Col>
        <Col xs="12" md="6">
          <div className="d-inline pt-2 cat-title">
            {(item.product && item.product.nameAr) || ""}
          </div>
          {showReviews ? (
            <div>
              {" "}
              <div className="d-inline pt-2 cat-rate me-2">
                {(item.product && item.product.ratingsAverage) || 0}
              </div>
              <div className="rate-count d-inline p-1 pt-2">
                ({(item.product && item.product.ratingsQuantity) || 0} {t("rating")})
              </div>
            </div>
          ) : (
            <div></div>
          )}

          <div className="mt-3 d-flex">
            <div className="cat-text mt-1 d-inline">{t("Quantity")}</div>
            <input
              value={item.quantity}
              className="mx-2 "
              type="number"
              style={{ width: "40px", height: "30px" }}
            />
            <div className="cat-text mt-1 d-inline">{t("weight")}</div>
            <div
              className="mx-2 mt-1"
            >
              {item.product.weight / 1000}
            </div>
            <div className="cat-text mt-1 d-inline">{t("Color")}</div>
            <div
              className="color ms-2 mx-2"
              style={{
                backgroundColor: item.color,
              }}
            ></div>
          </div>

          <div className="my-3 cat-text">{t("classification")} :</div>
          <div>{item?.variant?.title}</div>

          <div className="mt-3 d-flex">
            <div className="cat-text mt-1 d-inline">{t("Notes")}: </div>
            <div>  {item.productNotes}  </div>

          </div>
        </Col>
      </Row>
    </div>
  );
};

export default UserAllOrderCard;
